/* Button Styles */

:root {
  --primary: rgb(33, 37, 41);
  --bg: rgb(255, 255, 255);

  --shadow-1: 0 2px 5px rgba(0, 0, 0, 0.2);
  --shadow-2: 0 4px 10px rgba(0, 0, 0, 0.2);
}
table {
  width: 100%;
  border-collapse: collapse;
}
.error-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  text-align: center;
  padding: 60px;

  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 10px #aaa;
}

.error-message h1 {
  font-size: 28px;
  margin: 0;
}

/* Responsiveness */

@media (max-width: 768px) {
  .error-message {
    width: 90%;
    padding: 40px 20px;
  }

  .error-message h1 {
    font-size: 24px;
  }
}

th,
td {
  text-align: left;
  padding: 10px;
  border: 1px solid #eee;
}

th {
  background: #ddd;
}

tr:nth-child(even) {
  background: #f5f5f5;
}
body {
  background: var(--bg);
  transition: background-color 0.3s;
}

button {
  background: linear-gradient(
    to bottom,
    var(--primary),
    var(--primary) 50%,
    #333 50%,
    #333
  );
  color: var(--bg);
  padding: 12px 24px;
  border: none;
  border-radius: 5px;
  box-shadow: var(--shadow-1);
  transition: all 0.3s;
}

button:hover {
  transform: translateY(-2px);
  box-shadow: var(--shadow-2);
}

button:active {
  transform: translateY(2px);
  box-shadow: var(--shadow-1);
}
button.error {
  background: linear-gradient(
    to bottom,
    #b5322d,
    #bc3631 50%,
    #c9585c 50%,
    #c9585c
  );
  color: #fff !important;
  border: none;
  border-radius: 5px;
  box-shadow: var(--shadow-1);
  /* transition: all 0.3s; */
}

button.error:hover {
  transform: translateY(-2px);

  box-shadow: var(--shadow-2);
}

button.error:active {
  transform: translateY(2px);
  box-shadow: var(--shadow-1);
}
select {
  font-family: sans-serif !important;
  padding: 0.25rem 0.25rem !important;
  background-color: white !important;
}

@media (prefers-color-scheme: dark) {
  :root {
    --primary: rgb(255, 255, 255);
    --bg: rgb(33, 37, 41);
  }
}

/* Form Styles */

form {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 100px;
  width: 600px;
  box-shadow: 0 0 10px #ddd;
}

.form-group {
  margin-bottom: 20px;
}

h2 {
  font-size: 2rem;
}

.form-control {
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  form {
    padding: 20px;
  }

  .mob-width {
    width: 75% !important;
  }

  .form-control {
    padding: 8px;
  }
}
