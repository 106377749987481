.App-logo {
  height: 40vmin;
  pointer-events: none;
}
/* Adjust specific styles for dark mode */
@media (prefers-color-scheme: dark) {
  :root {
    --primary: rgb(33, 37, 41) !important;
    --bg: rgb(255, 255, 255) !important;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  z-index: 999;
}

.loader-icon {
  width: 80px;
  height: 80px;
  border: 5px solid #ccc;
  border-top-color: #333;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}

.loader-component {
  position: relative !important;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
