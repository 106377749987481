/* Styles */

/* Header */

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-info > div {
  margin-right: 20px;
  font-size: 14px;
}

.user-dropdown {
  position: relative;
  cursor: pointer;
}

.user-dropdown i {
  font-size: 24px;
}

.user-menu {
  position: absolute;
  top: 70px;
  right: 0;
  /* padding: 10px 0; */
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
}

/* Common styles for both .user-item and .user-heading */
.user-item,
.user-heading {
  padding: 10px;
  width: 190px;
  background: #ddd;
  color: black;
  box-shadow: var(--primary);
  transition: background 0.2s;
}

/* Styles specific to .user-item */
.user-item {
  cursor: pointer;
}

/* Styles specific to .user-heading */
.user-heading {
  cursor: inherit; /* Removing pointer cursor */
  border-bottom: 1px solid #ccc; /* Adding a bottom border for distinction */
  font-weight: bold; /* Making the heading text bold */
}

.user-item:hover {
  background: var(--primary);
  color: #fff;
  box-shadow: var(--shadow-2);
}

.container {
  max-width: 100% !important;
  padding: 0 !important;
  height: 100% !important;
  overflow-x: auto !important;       /* Enable horizontal scroll */
  overflow-y: auto !important;       /* Enable vertical scroll */
}

.header {
  background: var(--primary);
  color: #fff;
  padding: 20px;
  text-align: center;
  height: 88px;
}

.content {
  display: flex;
}

.sidebar {
  background: #f9f9f9;
  padding: 20px;
  width: 20%;
  height: calc(100vh - 88px);
}

.height-adjusted{
  height: calc(100vh - 88px) !important;
}

.tab {
  background: #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px;
}

.tab.active {
  background: var(--primary);
  color: #fff;
}

.main {
  flex: 1;
  padding: 20px;
}

/* Media Query */

@media (max-width: 768px) {
  .content {
    flex-direction: column;
  }
  .header {
    flex-direction: column;
    height: fit-content;
  }
  .user-menu {
    position: absolute;
    top: 125px;
    right: auto !important;
  }

  .user-info {
    margin-top: 15px;
    width: 100%;
    justify-content: center;
  }
  .user-info * {
    margin-right: 0 !important;
  }

  .sidebar {
    width: auto;
    height: fit-content;
  }
}
